var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isReady)?_c('nav',{attrs:{"aria-label":"pagination"}},[_c('ul',{staticClass:"pagination noselect"},[(_vm.pageJump)?_c('li',{staticClass:"page-item",class:{disabled: !_vm.hasPrevious},attrs:{"title":_vm.hasPrevious ? _vm.$t('titles.first_page') : ''}},[_c('span',{staticClass:"page-link",attrs:{"aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.page = 1}}},[_vm._m(0),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])])]):_vm._e(),_c('li',{staticClass:"page-item",class:{disabled: !_vm.hasPrevious},attrs:{"title":_vm.hasPrevious ? _vm.$t('titles.previous_page') : ''}},[_c('span',{staticClass:"page-link",attrs:{"aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.previous.apply(null, arguments)}}},[_vm._m(1),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])])]),_vm._l((_vm.pageInterval),function(pageNumber,ix){return _c('li',{key:ix,staticClass:"page-item",class:{active: _vm.page == pageNumber},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.page = pageNumber}}},[_c('span',{staticClass:"page-link"},[_vm._v(_vm._s(pageNumber))])])}),_c('li',{staticClass:"page-item",class:{disabled: !(_vm.hasNext || _vm.canLoadNext)},attrs:{"title":_vm.hasNext
          ? _vm.$t('titles.next_page')
          : _vm.canLoadNext
          ? _vm.$t('titles.load_next_page')
          : ''}},[_c('span',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.next.apply(null, arguments)}}},[(_vm.busy)?_c('span',{attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-refresh fa-spin"})]):[_vm._m(2),(!_vm.pageJump)?_c('span',{staticStyle:{"padding-left":"6px"}},[_vm._v(_vm._s(_vm.$tc("titles.next", 1)))]):_vm._e()]],2)]),(_vm.pageJump)?_c('li',{staticClass:"page-item",class:{disabled: !(_vm.hasNext || _vm.canLoadNext)},attrs:{"title":_vm.hasNext
          ? _vm.$t('titles.last_page')
          : _vm.canLoadNext
          ? _vm.$t('titles.last_page')
          : ''}},[_c('span',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.page = _vm.pages.length}}},[(_vm.busy)?_c('span',{attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-refresh fa-spin"})]):[_vm._m(3),(!_vm.pageJump)?_c('span',{staticStyle:{"padding-left":"6px"}},[_vm._v(_vm._s(_vm.$tc("titles.next", 1)))]):_vm._e()]],2)]):_vm._e()],2),(_vm.multiColumnOrder)?_c('ul',{staticClass:"pagination noselect"},[_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onShowSortForm.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-unsorted"})])])]):_vm._e(),_c('div',{staticClass:"pull-right"},[_vm._t("summary-before"),_c('div',{staticClass:"summary"},[_c('span',{attrs:{"title":_vm.$t('hints.showing_n_of_n_items', {
            items: (_vm.rows || []).length,
            total: _vm.nItems
          })}},[_vm._v(" "+_vm._s((_vm.rows || []).length)+" / "+_vm._s(_vm.nItems)+" ")])]),_vm._t("summary-after")],2)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"80%"},attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-fast-backward"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"80%"},attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-backward"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"80%"},attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-forward"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"80%"},attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa fa-fast-forward"})])
}]

export { render, staticRenderFns }